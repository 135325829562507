import * as React from 'react'
import { SignupForm } from '../Forms/Forms/SignupForm'
import { Modal } from '../Modal'
import { Image } from '../Image'

import { useShopData } from '../../providers/ShopDataProvider'
import { getPageLinkUrl } from '../../utils'
import router from 'next/router'
interface SignupModalProps {
  closeModal: () => void
}

export const SignupModal = ({ closeModal }: SignupModalProps) => {
  const { signupModalSettings } = useShopData()

  const { href, as } = signupModalSettings?.submittedButtonLink?.document
    ? getPageLinkUrl(
        signupModalSettings.submittedButtonLink.document,
        (signupModalSettings.submittedButtonLink
          .queryParams as unknown as Record<
          string,
          string | number | boolean
        >) || {},
      )
    : { href: '', as: '' }

  const handleContinue = () => {
    closeModal()
    // route to internal link
    router.push(href, as)
  }

  return (
    <Modal display="signup" closeModal={closeModal}>
      <Image image={signupModalSettings?.image} preload objectFit="cover" />
      <Image
        image={signupModalSettings?.mobileImage}
        preload
        objectFit="cover"
      />
      <SignupForm settings={signupModalSettings} onContinue={handleContinue} />
    </Modal>
  )
}
