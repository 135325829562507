export const countryOptions = [
  { id: 'Canada', label: 'Canada', value: 'Canada' },
  { id: 'United States', label: 'United States', value: 'United States' },
  { id: 'Australia', label: 'Australia', value: 'Australia' },
  { id: 'Mexico', label: 'Mexico', value: 'Mexico' },
  { id: 'Albania', label: 'Albania', value: 'Albania' },
  { id: 'Germany', label: 'Germany', value: 'Germany' },
  { id: 'Austria', label: 'Austria', value: 'Austria' },
  { id: 'Belgium', label: 'Belgium', value: 'Belgium' },
  { id: 'Belarus', label: 'Belarus', value: 'Belarus' },
  { id: 'Bulgaria', label: 'Bulgaria', value: 'Bulgaria' },
  { id: 'Croatia', label: 'Croatia', value: 'Croatia' },
  { id: 'Greece', label: 'Greece', value: 'Greece' },
  { id: 'Denmark', label: 'Denmark', value: 'Denmark' },
  { id: 'Scotland', label: 'Scotland', value: 'Scotland' },
  { id: 'Slovakia', label: 'Slovakia', value: 'Slovakia' },
  { id: 'Slovenia', label: 'Slovenia', value: 'Slovenia' },
  { id: 'Spain', label: 'Spain', value: 'Spain' },
  { id: 'Estonia', label: 'Estonia', value: 'Estonia' },
  { id: 'Finland', label: 'Finland', value: 'Finland' },
  { id: 'France', label: 'France', value: 'France' },
  { id: 'Hungary', label: 'Hungary', value: 'Hungary' },
  { id: 'England', label: 'England', value: 'England' },
  { id: 'Iceland', label: 'Iceland', value: 'Iceland' },
  { id: 'Italy', label: 'Italy', value: 'Italy' },
  { id: 'Ireland', label: 'Ireland', value: 'Ireland' },
  { id: 'Latvia', label: 'Latvia', value: 'Latvia' },
  { id: 'Lithuania', label: 'Lithuania', value: 'Lithuania' },
  { id: 'Macedonia', label: 'Macedonia', value: 'Macedonia' },
  { id: 'Malta', label: 'Malta', value: 'Malta' },
  { id: 'Montenegro', label: 'Montenegro', value: 'Montenegro' },
  { id: 'Moldavia', label: 'Moldavia', value: 'Moldavia' },
  { id: 'Netherlands', label: 'Netherlands', value: 'Netherlands' },
  { id: 'Norway', label: 'Norway', value: 'Norway' },
  { id: 'Poland', label: 'Poland', value: 'Poland' },
  { id: 'Portugal', label: 'Portugal', value: 'Portugal' },
  { id: 'United Kingdom', label: 'United Kingdom', value: 'United Kingdom' },
  { id: 'Czech Republic', label: 'Czech Republic', value: 'Czech Republic' },
  { id: 'Romania', label: 'Romania', value: 'Romania' },
  { id: 'Serbia', label: 'Serbia', value: 'Serbia' },
  { id: 'Sweden', label: 'Sweden', value: 'Sweden' },
  { id: 'Switzerland', label: 'Switzerland', value: 'Switzerland' },
  { id: 'Ukraine', label: 'Ukraine', value: 'Ukraine' },
  { id: 'Afghanistan', label: 'Afghanistan', value: 'Afghanistan' },
  { id: 'Armenia', label: 'Armenia', value: 'Armenia' },
  { id: 'Azerbaijan', label: 'Azerbaijan', value: 'Azerbaijan' },
  { id: 'Bahrain', label: 'Bahrain', value: 'Bahrain' },
  { id: 'Bangladesh', label: 'Bangladesh', value: 'Bangladesh' },
  { id: 'Brunei', label: 'Brunei', value: 'Brunei' },
  { id: 'Cambodia', label: 'Cambodia', value: 'Cambodia' },
  { id: 'China', label: 'China', value: 'China' },
  { id: 'North Korea', label: 'North Korea', value: 'North Korea' },
  { id: 'South Korea', label: 'South Korea', value: 'South Korea' },
  { id: 'Philippines', label: 'Philippines', value: 'Philippines' },
  { id: 'Georgia', label: 'Georgia', value: 'Georgia' },
  { id: 'India', label: 'India', value: 'India' },
  { id: 'Indonesia', label: 'Indonesia', value: 'Indonesia' },
  { id: 'Iraq', label: 'Iraq', value: 'Iraq' },
  { id: 'Iran', label: 'Iran', value: 'Iran' },
  { id: 'Israel', label: 'Israel', value: 'Israel' },
  { id: 'Japan', label: 'Japan', value: 'Japan' },
  { id: 'Jordan', label: 'Jordan', value: 'Jordan' },
  { id: 'Kazakhstan', label: 'Kazakhstan', value: 'Kazakhstan' },
  { id: 'Kyrgyzstan', label: 'Kyrgyzstan', value: 'Kyrgyzstan' },
  { id: 'Malaysia', label: 'Malaysia', value: 'Malaysia' },
  { id: 'Maldivas', label: 'Maldivas', value: 'Maldivas' },
  { id: 'Mongolia', label: 'Mongolia', value: 'Mongolia' },
  { id: 'Oman', label: 'Oman', value: 'Oman' },
  { id: 'Palestine', label: 'Palestine', value: 'Palestine' },
  { id: 'Pakistan', label: 'Pakistan', value: 'Pakistan' },
  { id: 'Russia', label: 'Russia', value: 'Russia' },
  { id: 'Singapore', label: 'Singapore', value: 'Singapore' },
  { id: 'Syria', label: 'Syria', value: 'Syria' },
  { id: 'Thailand', label: 'Thailand', value: 'Thailand' },
  { id: 'East Timor', label: 'East Timor', value: 'East Timor' },
  { id: 'Turkey', label: 'Turkey', value: 'Turkey' },
  { id: 'Uzbekistan', label: 'Uzbekistan', value: 'Uzbekistan' },
  { id: 'Vietnam', label: 'Vietnam', value: 'Vietnam' },
  { id: 'Yemen', label: 'Yemen', value: 'Yemen' },
  { id: 'Angola', label: 'Angola', value: 'Angola' },
  { id: 'Algeria', label: 'Algeria', value: 'Algeria' },
  { id: 'Benin', label: 'Benin', value: 'Benin' },
  { id: 'Botswana', label: 'Botswana', value: 'Botswana' },
  { id: 'Burkina Faso', label: 'Burkina Faso', value: 'Burkina Faso' },
  { id: 'Burundi', label: 'Burundi', value: 'Burundi' },
  { id: 'Cape Verde', label: 'Cape Verde', value: 'Cape Verde' },
  { id: 'Cameroon', label: 'Cameroon', value: 'Cameroon' },
  { id: 'Chad', label: 'Chad', value: 'Chad' },
  { id: 'Comoros', label: 'Comoros', value: 'Comoros' },
  { id: 'Egypt', label: 'Egypt', value: 'Egypt' },
  { id: 'Eritrea', label: 'Eritrea', value: 'Eritrea' },
  { id: 'Ethiopia', label: 'Ethiopia', value: 'Ethiopia' },
  { id: 'Gabon', label: 'Gabon', value: 'Gabon' },
  { id: 'Gambia', label: 'Gambia', value: 'Gambia' },
  { id: 'Ghana', label: 'Ghana', value: 'Ghana' },
  { id: 'Guinea', label: 'Guinea', value: 'Guinea' },
  { id: 'Kenya', label: 'Kenya', value: 'Kenya' },
  { id: 'Liberia', label: 'Liberia', value: 'Liberia' },
  { id: 'Libya', label: 'Libya', value: 'Libya' },
  { id: 'Madagascar', label: 'Madagascar', value: 'Madagascar' },
  { id: 'Malawi', label: 'Malawi', value: 'Malawi' },
  { id: 'Mali', label: 'Mali', value: 'Mali' },
  { id: 'Morocco', label: 'Morocco', value: 'Morocco' },
  { id: 'Mauricio', label: 'Mauricio', value: 'Mauricio' },
  { id: 'Mauritania', label: 'Mauritania', value: 'Mauritania' },
  { id: 'Mozambique', label: 'Mozambique', value: 'Mozambique' },
  { id: 'Namibia', label: 'Namibia', value: 'Namibia' },
  { id: 'Niger', label: 'Niger', value: 'Niger' },
  {
    id: 'Central African Republic',
    label: 'Central African Republic',
    value: 'Central African Republic',
  },
  {
    id: 'Republic of Congo',
    label: 'Republic of Congo',
    value: 'Republic of Congo',
  },
  { id: 'Rwanda', label: 'Rwanda', value: 'Rwanda' },
  { id: 'Senegal', label: 'Senegal', value: 'Senegal' },
  { id: 'Seychelles', label: 'Seychelles', value: 'Seychelles' },
  { id: 'Sierra Leone', label: 'Sierra Leone', value: 'Sierra Leone' },
  { id: 'Somalia', label: 'Somalia', value: 'Somalia' },
  { id: 'Swaziland', label: 'Swaziland', value: 'Swaziland' },
  { id: 'South Africa', label: 'South Africa', value: 'South Africa' },
  { id: 'Sudan', label: 'Sudan', value: 'Sudan' },
  { id: 'Tanzania', label: 'Tanzania', value: 'Tanzania' },
  { id: 'Togo', label: 'Togo', value: 'Togo' },
  { id: 'Tunisia', label: 'Tunisia', value: 'Tunisia' },
  { id: 'Uganda', label: 'Uganda', value: 'Uganda' },
  { id: 'Zambia', label: 'Zambia', value: 'Zambia' },
  { id: 'Zimbabwe', label: 'Zimbabwe', value: 'Zimbabwe' },
  { id: 'Argentina', label: 'Argentina', value: 'Argentina' },
  { id: 'Bolivia', label: 'Bolivia', value: 'Bolivia' },
  { id: 'Brazil', label: 'Brazil', value: 'Brazil' },
  { id: 'Chile', label: 'Chile', value: 'Chile' },
  { id: 'Colombia', label: 'Colombia', value: 'Colombia' },
  { id: 'Ecuador', label: 'Ecuador', value: 'Ecuador' },
  { id: 'Paraguay', label: 'Paraguay', value: 'Paraguay' },
  { id: 'Peru', label: 'Peru', value: 'Peru' },
  { id: 'Uruguay', label: 'Uruguay', value: 'Uruguay' },
  { id: 'Venezuela', label: 'Venezuela', value: 'Venezuela' },
  { id: 'Belize', label: 'Belize', value: 'Belize' },
  { id: 'Costa Rica', label: 'Costa Rica', value: 'Costa Rica' },
  { id: 'Cuba', label: 'Cuba', value: 'Cuba' },
  { id: 'Guatemala', label: 'Guatemala', value: 'Guatemala' },
  { id: 'Honduras', label: 'Honduras', value: 'Honduras' },
  { id: 'Nicaragua', label: 'Nicaragua', value: 'Nicaragua' },
  { id: 'Panama', label: 'Panama', value: 'Panama' },
  { id: 'Puerto Rico', label: 'Puerto Rico', value: 'Puerto Rico' },
  {
    id: 'Dominican Republic',
    value: 'Dominican Republic',
    label: 'Dominican Republic',
  },
  { id: 'El Salvador', value: 'El Salvador', label: 'El Salvador' },
].map(({ id, label, value }) => ({
  label,
  value,
  id: id.replace(/\s/, '-').toLowerCase(),
}))
