import * as React from 'react'
import { ContactForm } from '../Forms'
import { Modal } from '../Modal'
import { ContactFormModalProps } from '../../types/modal'
export const ContactFormModal = ({
  formtype,
  closeModal,
}: ContactFormModalProps) => (
  <Modal closeModal={closeModal}>
    <ContactForm formtype={formtype} />
  </Modal>
)
