export const sizeConversionOptions = [
  {
    mm: 44,
    inches: 1.74,
    us: 3,
    can: 3,
    uk: 'F ½',
    aus: 'F ½',
    sa: 'F ½',
    fr: 44,
    in: 4,
    cn: 4,
    jp: 4,
    arg: 4,
    bo: 4,
    br: 4,
    cl: 4,
    col: 4,
    ecua: 4,
    guy: 4,
    para: 4,
    per: 4,
    surm: 4,
    uru: 4,
    vz: 4,
    tr: 4,
    il: 4,
  },
  {
    mm: 45,
    inches: 1.78,
    us: 3.5,
    can: 3.5,
    uk: 'G',
    aus: 'G',
    sa: 'G',
    fr: 45,
    in: 5,
    cn: 5,
    jp: 5,
    arg: 5,
    bo: 5,
    br: 5,
    cl: 5,
    col: 5,
    ecua: 5,
    guy: 5,
    para: 5,
    per: 5,
    surm: 5,
    uru: 5,
    vz: 5,
    tr: 5,
    il: 5,
  },
  {
    mm: 47,
    inches: 1.86,
    us: 4,
    can: 4,
    uk: 'H ½',
    aus: 'H ½',
    sa: 'H ½',
    fr: 47,
    in: 7,
    cn: 7,
    jp: 7,
    arg: 7,
    bo: 7,
    br: 7,
    cl: 7,
    col: 7,
    ecua: 7,
    guy: 7,
    para: 7,
    per: 7,
    surm: 7,
    uru: 7,
    vz: 7,
    tr: 7,
    il: 7,
  },
  {
    mm: 48,
    inches: 1.89,
    us: 4.5,
    can: 4.5,
    uk: 'I ½',
    aus: 'I ½',
    sa: 'I ½',
    fr: 48,
    in: 8,
    cn: 8,
    jp: 8,
    arg: 8,
    bo: 8,
    br: 8,
    cl: 8,
    col: 8,
    ecua: 8,
    guy: 8,
    para: 8,
    per: 8,
    surm: 8,
    uru: 8,
    vz: 8,
    tr: 8,
    il: 8,
  },
  {
    mm: 49,
    inches: 1.93,
    us: 5,
    can: 5,
    uk: 'J ½',
    aus: 'J ½',
    sa: 'J ½',
    fr: 49,
    in: 9,
    cn: 9,
    jp: 9,
    arg: 9,
    bo: 9,
    br: 9,
    cl: 9,
    col: 9,
    ecua: 9,
    guy: 9,
    para: 9,
    per: 9,
    surm: 9,
    uru: 9,
    vz: 9,
    tr: 9,
    il: 9,
  },
  {
    mm: 50,
    inches: 1.97,
    us: 5.5,
    can: 5.5,
    uk: 'K',
    aus: 'K',
    sa: 'K',
    fr: 50,
    in: 10,
    cn: 10,
    jp: 10,
    arg: 10,
    bo: 10,
    br: 10,
    cl: 10,
    col: 10,
    ecua: 10,
    guy: 10,
    para: 10,
    per: 10,
    surm: 10,
    uru: 10,
    vz: 10,
    tr: 10,
    il: 10,
  },
  {
    mm: 52,
    inches: 2.05,
    us: 6,
    can: 6,
    uk: 'L ½',
    aus: 'L ½',
    sa: 'L ½',
    fr: 52,
    in: 12,
    cn: 12,
    jp: 12,
    arg: 12,
    bo: 12,
    br: 12,
    cl: 12,
    col: 12,
    ecua: 12,
    guy: 12,
    para: 12,
    per: 12,
    surm: 12,
    uru: 12,
    vz: 12,
    tr: 12,
    il: 12,
  },
  {
    mm: 53,
    inches: 2.09,
    us: 6.5,
    can: 6.5,
    uk: 'M ½',
    aus: 'M ½',
    sa: 'M ½',
    fr: 53,
    in: 13,
    cn: 13,
    jp: 13,
    arg: 13,
    bo: 13,
    br: 13,
    cl: 13,
    col: 13,
    ecua: 13,
    guy: 13,
    para: 13,
    per: 13,
    surm: 13,
    uru: 13,
    vz: 13,
    tr: 13,
    il: 13,
  },
  {
    mm: 55,
    inches: 2.17,
    us: 7,
    can: 7,
    uk: 'O',
    aus: 'O',
    sa: 'O',
    fr: 55,
    in: 15,
    cn: 15,
    jp: 15,
    arg: 15,
    bo: 15,
    br: 15,
    cl: 15,
    col: 15,
    ecua: 15,
    guy: 15,
    para: 15,
    per: 15,
    surm: 15,
    uru: 15,
    vz: 15,
    tr: 15,
    il: 15,
  },
  {
    mm: 56,
    inches: 2.21,
    us: 7.5,
    can: 7.5,
    uk: 'P',
    aus: 'P',
    sa: 'P',
    fr: 56,
    in: 16,
    cn: 16,
    jp: 16,
    arg: 16,
    bo: 16,
    br: 16,
    cl: 16,
    col: 16,
    ecua: 16,
    guy: 16,
    para: 16,
    per: 16,
    surm: 16,
    uru: 16,
    vz: 16,
    tr: 16,
    il: 16,
  },
  {
    mm: 57,
    inches: 2.25,
    us: 8,
    can: 8,
    uk: 'P ½',
    aus: 'P ½',
    sa: 'P ½',
    fr: 57,
    in: 17,
    cn: 17,
    jp: 17,
    arg: 17,
    bo: 17,
    br: 17,
    cl: 17,
    col: 17,
    ecua: 17,
    guy: 17,
    para: 17,
    per: 17,
    surm: 17,
    uru: 17,
    vz: 17,
    tr: 17,
    il: 17,
  },
  {
    mm: 59,
    inches: 2.33,
    us: 8.5,
    can: 8.5,
    uk: 'R',
    aus: 'R',
    sa: 'R',
    fr: 59,
    in: 19,
    cn: 19,
    jp: 19,
    arg: 19,
    bo: 19,
    br: 19,
    cl: 19,
    col: 19,
    ecua: 19,
    guy: 19,
    para: 19,
    per: 19,
    surm: 19,
    uru: 19,
    vz: 19,
    tr: 19,
    il: 19,
  },
  {
    mm: 60,
    inches: 2.37,
    us: 9,
    can: 9,
    uk: 'S',
    aus: 'S',
    sa: 'S',
    fr: 60,
    in: 20,
    cn: 20,
    jp: 20,
    arg: 20,
    bo: 20,
    br: 20,
    cl: 20,
    col: 20,
    ecua: 20,
    guy: 20,
    para: 20,
    per: 20,
    surm: 20,
    uru: 20,
    vz: 20,
    tr: 20,
    il: 20,
  },
  {
    mm: 61,
    inches: 2.41,
    us: 9.5,
    can: 9.5,
    uk: 'S ½',
    aus: 'S ½',
    sa: 'S ½',
    fr: 61,
    in: 21,
    cn: 21,
    jp: 21,
    arg: 21,
    bo: 21,
    br: 21,
    cl: 21,
    col: 21,
    ecua: 21,
    guy: 21,
    para: 21,
    per: 21,
    surm: 21,
    uru: 21,
    vz: 21,
    tr: 21,
    il: 21,
  },
  {
    mm: 62,
    inches: 2.45,
    us: 10,
    can: 10,
    uk: 'T ½',
    aus: 'T ½',
    sa: 'T ½',
    fr: 62,
    in: 22,
    cn: 22,
    jp: 22,
    arg: 22,
    bo: 22,
    br: 22,
    cl: 22,
    col: 22,
    ecua: 22,
    guy: 22,
    para: 22,
    per: 22,
    surm: 22,
    uru: 22,
    vz: 22,
    tr: 22,
    il: 22,
  },
  {
    mm: 64,
    inches: 2.52,
    us: 10.5,
    can: 10.5,
    uk: 'V',
    aus: 'V',
    sa: 'V',
    fr: 64,
    in: 24,
    cn: 24,
    jp: 24,
    arg: 24,
    bo: 24,
    br: 24,
    cl: 24,
    col: 24,
    ecua: 24,
    guy: 24,
    para: 24,
    per: 24,
    surm: 24,
    uru: 24,
    vz: 24,
    tr: 24,
    il: 24,
  },
  {
    mm: 65,
    inches: 2.56,
    us: 11,
    can: 11,
    uk: 'W',
    aus: 'W',
    sa: 'W',
    fr: 65,
    in: 25,
    cn: 25,
    jp: 25,
    arg: 25,
    bo: 25,
    br: 25,
    cl: 25,
    col: 25,
    ecua: 25,
    guy: 25,
    para: 25,
    per: 25,
    surm: 25,
    uru: 25,
    vz: 25,
    tr: 25,
    il: 25,
  },
  {
    mm: 66,
    inches: 2.6,
    us: 11.5,
    can: 11.5,
    uk: 'W ½',
    aus: 'W ½',
    sa: 'W ½',
    fr: 66,
    in: 26,
    cn: 26,
    jp: 26,
    arg: 26,
    bo: 26,
    br: 26,
    cl: 26,
    col: 26,
    ecua: 26,
    guy: 26,
    para: 26,
    per: 26,
    surm: 26,
    uru: 26,
    vz: 26,
    tr: 26,
    il: 26,
  },
  {
    mm: 68,
    inches: 2.68,
    us: 12,
    can: 12,
    uk: 'Z',
    aus: 'Z',
    sa: 'Z',
    fr: 68,
    in: 28,
    cn: 28,
    jp: 28,
    arg: 28,
    bo: 28,
    br: 28,
    cl: 28,
    col: 28,
    ecua: 28,
    guy: 28,
    para: 28,
    per: 28,
    surm: 28,
    uru: 28,
    vz: 28,
    tr: 28,
    il: 28,
  },
  {
    mm: 69,
    inches: 2.72,
    us: 12.5,
    can: 12.5,
    uk: 'Z ½',
    aus: 'Z ½',
    sa: 'Z ½',
    fr: 69,
    in: 29,
    cn: 29,
    jp: 29,
    arg: 29,
    bo: 29,
    br: 29,
    cl: 29,
    col: 29,
    ecua: 29,
    guy: 29,
    para: 29,
    per: 29,
    surm: 29,
    uru: 29,
    vz: 29,
    tr: 29,
    il: 29,
  },
  {
    mm: 70,
    inches: 2.76,
    us: 13,
    can: 13,
    uk: '',
    aus: '',
    sa: '',
    fr: 70,
    in: 30,
    cn: 30,
    jp: 30,
    arg: 30,
    bo: 30,
    br: 30,
    cl: 30,
    col: 30,
    ecua: 30,
    guy: 30,
    para: 30,
    per: 30,
    surm: 30,
    uru: 30,
    vz: 30,
    tr: 30,
    il: 30,
  },
  {
    mm: 71,
    inches: 2.8,
    us: 13.5,
    can: 13.5,
    uk: '',
    aus: '',
    sa: '',
    fr: 71,
    in: 31,
    cn: 31,
    jp: 31,
    arg: 31,
    bo: 31,
    br: 31,
    cl: 31,
    col: 31,
    ecua: 31,
    guy: 31,
    para: 31,
    per: 31,
    surm: 31,
    uru: 31,
    vz: 31,
    tr: 31,
    il: 31,
  },
].map((o) => ({
  ...o,
  label: o['us'],
  value: o['us'],
  id: o.mm.toString(),
}))
