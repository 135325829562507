import * as React from 'react'
import { Product, ShopifyProductVariant } from '../../types'
import { Modal } from '../Modal'
import { HighValueForm } from '../Forms/Forms'
import { CustomizationModalProps } from '../../types/modal'

export const HighValueCustomizationModal = ({
  closeModal,
  product,
  variant,
}: CustomizationModalProps) => {
  return (
    <Modal closeModal={closeModal}>
      <HighValueForm product={product} variant={variant} />
    </Modal>
  )
}
