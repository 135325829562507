export const inquiryTypeOptions = [
  {
    id: 'Returns & Exchanges',
    value: 'Returns & Exchanges',
    label: 'Returns & Exchanges',
  },
  {
    id: 'Start a Repair',
    value: 'Start a Repair',
    label: 'Start a Repair',
  },
  {
    id: 'Place an Order',
    value: 'Place an Order',
    label: 'Place an Order',
  },
  {
    id: 'Customization Inquiry',
    value: 'Customization Inquiry',
    label: 'Customization Inquiry',
  },
]
