'use client'

import { useEffect } from 'react'
import { useModal } from '../providers'
import { getCookie } from '../utils'

export function SignupModalLoader() {
  const { openSignupModal } = useModal()
  const signupSubmitted = getCookie('SK_SIGNUP_SUBMITTED')
  const signupClosed = getCookie('SK_SIGNUP_CLOSED')

  useEffect(() => {
    // Only show the modal in non-production environments
    if (
      typeof window !== 'undefined' &&
      process.env.NODE_ENV !== 'production' &&
      !signupSubmitted &&
      !signupClosed
    ) {
      const modalTimeout = setTimeout(() => {
        openSignupModal()
      }, 1000)
      return () => clearTimeout(modalTimeout)
    }
  }, [])

  return null
}
