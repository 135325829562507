import { useState, useEffect } from 'react'

export const useExchangeRate = (currency: string) => {
  const [exchangeRate, setExchangeRate] = useState<string>(currency)

  const fetchExchangeRates = async (currency: string) => {
    const primaryUrl =
      'https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/usd.min.json'
    const fallbackUrl =
      'https://currency-api.pages.dev/v1/currencies/usd.min.json'

    try {
      const response = await fetch(primaryUrl)
      if (!response.ok) {
        throw new Error(`Primary API failed with status: ${response.status}`)
      }
      const data = await response.json()
      return data.usd[currency] // Returns exchange rates in comparison to USD
    } catch (error) {
      console.error('Primary API failed:', error)

      // Attempt to fetch from the fallback URL
      try {
        const fallbackResponse = await fetch(fallbackUrl)
        if (!fallbackResponse.ok) {
          throw new Error(
            `Fallback API failed with status: ${fallbackResponse.status}`,
          )
        }
        const fallbackData = await fallbackResponse.json()
        return fallbackData.usd[currency]
      } catch (fallbackError) {
        console.error('Fallback API failed:', fallbackError)
        return 1
      }
    }
  }

  useEffect(() => {
    const getExchangeRate = async () => {
      const rate = await fetchExchangeRates(currency)
      setExchangeRate(rate ?? 1)
    }
    getExchangeRate()
  }, [currency])

  return exchangeRate
}

export default useExchangeRate
