import SHA1 from 'crypto-js/sha1'

export const generateEmailUUID = async (email: string) => {
  if (email === '') return ''

  const hash = SHA1(email)

  // Convert hash to an array of bytes
  const digest: number[] = []
  for (let i = 0; i < hash.sigBytes; i++) {
    const byte = (hash.words[Math.floor(i / 4)] >>> (24 - (i % 4) * 8)) & 0xff
    digest.push(byte)
  }

  // Convert bytes to hex string, adjusting negative bytes
  const hexHash = digest
    .map(function (byte) {
      const v = (byte < 0 ? byte + 256 : byte).toString(16)
      return v.length === 1 ? '0' + v : v
    })
    .join('')

  // Format the hash to look like a UUID (8-4-4-4-12)
  const uuid = [
    hexHash.slice(0, 8),
    hexHash.slice(8, 12),
    hexHash.slice(12, 16),
    hexHash.slice(16, 20),
    hexHash.slice(20, 32),
  ].join('-')
  return uuid
}
